(function () {
    'use strict';

    angular.module('mfgApp')
        .run(mfgAppRoutesToStyles);

    mfgAppRoutesToStyles.$inject = ['PageService', 'routesConstant'];

    function mfgAppRoutesToStyles(PageService, routesConstant) {
        PageService.addAflPageViewToStateMap(routesConstant.MFG.MAIN.stateName, PageService.AFLPAGEVIEW.MFG);
    }
})();
